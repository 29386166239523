import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { EyecatchImage } from "./eyecatchImage"

const StyledFixedPost = styled.div`
  display: grid;
  row-gap: 30px;
  padding-bottom: 30px;
  background-color: #ffffff;

  figure {
    height: 200px;

    & img {
      height: 100%;
      object-fit: cover;
    }
  }

  .title {
    padding: 0 10px;
    font-size: var(--f4);
    text-align: center;
  }

  .more {
    align-self: end;
  }

  @media (min-width: 768px) {
    grid-column: span 4 !important;
  }
`

export const StyledReadMore = styled(Link)`
  justify-self: center;
  border: solid 1px currentColor;
  padding: 15px 30px;

  @media (min-width: 768px) {
    font-size: var(--f4);
  }
`

export const FixedPost = () => {
  const data = useStaticQuery(graphql`
    query {
      home5g: wpPost(slug: { eq: "buy-home5g" }) {
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            sourceUrl
            altText
            mediaDetails {
              width
              height
            }
          }
        }
        title
        slug
      }
      pancake: wpPost(slug: { eq: "start-pancakeswap" }) {
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            sourceUrl
            altText
            mediaDetails {
              width
              height
            }
          }
        }
        title
        slug
      }
      registerA8: wpPost(slug: { eq: "register-a8net" }) {
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            sourceUrl
            altText
            mediaDetails {
              width
              height
            }
          }
        }
        title
        slug
      }
    }
  `)

  return (
    <>
      <StyledFixedPost>
        <EyecatchImage
          featuredImage={data.home5g.featuredImage}
          image={
            data.home5g.featuredImage.node.localFile.childImageSharp
              .gatsbyImageData
          }
          alt={data.home5g.title}
        />
        <Link to={`/blog/${data.home5g.slug}/`} className="title">
          {data.home5g.title}
        </Link>
        <StyledReadMore to={`/blog/${data.home5g.slug}/`} className="more">
          記事を読む
        </StyledReadMore>
      </StyledFixedPost>
      <StyledFixedPost>
        <EyecatchImage
          featuredImage={data.pancake.featuredImage}
          image={
            data.pancake.featuredImage.node.localFile.childImageSharp
              .gatsbyImageData
          }
          alt={data.pancake.title}
        />
        <Link to={`/blog/${data.pancake.slug}/`} className="title">
          {data.pancake.title}
        </Link>
        <StyledReadMore to={`/blog/${data.pancake.slug}/`} className="more">
          記事を読む
        </StyledReadMore>
      </StyledFixedPost>
      <StyledFixedPost>
        <EyecatchImage
          featuredImage={data.registerA8.featuredImage}
          image={
            data.registerA8.featuredImage.node.localFile.childImageSharp
              .gatsbyImageData
          }
          alt={data.registerA8.title}
        />
        <Link to={`/blog/${data.registerA8.slug}/`} className="title">
          {data.registerA8.title}
        </Link>
        <StyledReadMore to={`/blog/${data.registerA8.slug}/`} className="more">
          記事を読む
        </StyledReadMore>
      </StyledFixedPost>
    </>
  )
}
