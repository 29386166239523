import * as React from "react"
import { FixedPost } from "../components/fixedPost"
import { Layout } from "../components/layout"
import { Seo } from "../components/seo"
import { Sidebar } from "../components/sidebar"
import { StyledWrapper } from "../components/wrapper"

const IndexPage = () => {
  return (
    <Layout>
      <Seo />
      <StyledWrapper>
        <div>
          <FixedPost />
        </div>
        <div>
          <Sidebar />
        </div>
      </StyledWrapper>
    </Layout>
  )
}

export default IndexPage
